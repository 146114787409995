import React from "react";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import ShareView from "../components/ShareView";
import { shareGift, resetShareGift } from "../store/user/actions";
import { getStyles } from "../store/selectors";

const SharePage = (props) => <ShareView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    user,
    app: { keyboardOpen },
  } = state;

  return { user, keyboardOpen, appStyles: getStyles(state, props) };
};

const mapDisaptchToProps = (dispatch, props) => {
  return {
    shareGift: ({
      fromUserName,
      personalMessage,
      toUserPhonenumber,
      toUserName,
    }) =>
      dispatch(
        shareGift({
          fromUserName,
          personalMessage,
          toUserPhonenumber,
          toUserName,
        }),
      ),
    resetShareGift: () => dispatch(resetShareGift()),
  };
};

export default connect(
  mapStateToProps,
  mapDisaptchToProps,
)(withTranslation(SharePage));
